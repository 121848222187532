.border-fade {
    position: relative;
  }
  
  .border-fade::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, transparent, rgb(0, 0, 0), transparent);
  }